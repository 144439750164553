import { Box, Typography } from '@mui/material';
import React from 'react';
import officeSpace1 from '../../images/officeSpace1.jpg'
import officeSpace2 from '../../images/officeSpace2.jpg'

const AboutUs = () => {
    return (
        <Box sx={{ padding: '1em' }}>
            <Typography sx={{ color: '#424242', fontWeight: "bold" }} variant='h3'>About Us</Typography>
            <Typography variant='h4' >Welcome to Supreme Supplies BD-</Typography>
            <section>
                <Box sx={{ display: 'inline-flex', justifyContent: "space-around", alignItems: 'center', flexDirection: { xs: 'column-reverse', md: 'row', lg: 'row' } }}>
                    <Box sx={{ padding: "0 1em" }}>
                        <Typography variant='p'>Your ultimate destination for a diverse range of high-quality products and services across multiple sectors. Founded with a vision to revolutionize the way businesses and individuals source their essentials, we take pride in offering an extensive array of accessories, IT solutions, agro products, garments, and distribution services.</Typography>
                        <Typography variant='p'>At Supreme Supplies BD, we understand the importance of catering to various needs under one roof. Whether you're seeking cutting-edge technology solutions, top-notch agricultural supplies, trendy garments, or efficient distribution services, we've got you covered. Our commitment to excellence and customer satisfaction drives everything we do.</Typography>
                        <Typography variant='p'>In the realm of accessories, we bring you a curated selection of premium products designed to enhance your lifestyle and productivity. From stylish phone cases to ergonomic laptop stands, our collection combines functionality with aesthetics to meet the demands of modern consumers.</Typography>
                        <Typography variant='p'>Our IT division stands at the forefront of innovation, offering comprehensive solutions tailored to your business requirements. Whether you're a startup looking to establish your digital presence or an enterprise seeking to streamline operations, our team of experts is dedicated to delivering seamless IT solutions that drive growth and efficiency.</Typography>
                    </Box>
                    <Box> <img style={{ width: '100%' }} src={officeSpace1} alt="elasticImage" /></Box>
                </Box>
                <Box sx={{ display: 'inline-flex', justifyContent: "space-around", alignItems: 'center', flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}>
                    <Box> <img style={{ width: '100%', height: '100%' }} src={officeSpace2} alt="fishFood" /></Box>
                    <Box sx={{ padding: "0 1em" }}>
                        <Typography variant='p'>In the agro sector, we specialize in providing high-quality seeds and fisheries products to farmers and aquaculturists across the region. With a focus on sustainability and productivity, we empower agricultural communities with the tools and resources needed to thrive in a rapidly evolving industry.</Typography>
                        <Typography variant='p'>In addition to accessories, IT, and agro, our garments division showcases the latest trends in fashion and apparel. From casual wear to formal attire, we offer a diverse range of clothing options for men, women, and children, sourced from reputable manufacturers known for their craftsmanship and quality.</Typography><br />
                        <Typography variant='p'>Furthermore, our distribution services ensure that products reach their intended destinations efficiently and cost-effectively. With a robust logistics network and a team of experienced professionals, we handle everything from warehousing to transportation, allowing businesses to focus on their core operations while we take care of the rest.</Typography><br />
                        <Typography variant='p'>As we continue to grow and expand, Supreme Supplies BD remains committed to delivering excellence in every aspect of our business. Whether you're a small business owner, a seasoned farmer, or a fashion enthusiast, we invite you to experience the Supreme difference and join us on our journey towards a brighter future.</Typography><br />
                        <Typography variant='p'>Thank you for choosing Supreme Supplies BD – where quality meets convenience, and customer satisfaction is our top priority.</Typography><br />

                    </Box>
                </Box>
            </section>


        </Box>
    );
};

export default AboutUs;