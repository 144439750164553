import React from 'react';
import { Box, Typography } from '@mui/material';
import machine from '../../images/machine.jpg'
import buyingHouse from '../../images/buyingHouse.jpg'


const Garments = () => {
    return (
        <Box sx={{padding:'1em'}}>
        <Typography variant='h3'>Garments</Typography>
        <Typography variant='p'><b>Supreme Supplies BD</b> your trusted partner in the world of fashion and textile manufacturing. With a comprehensive range of services encompassing both buying house operations and clothing production, we are committed to delivering excellence and innovation to our clients across the globe.</Typography>
        <section>
        <Box sx={{display:'inline-flex', justifyContent:"space-around",alignItems:'center',flexDirection:{xs:'column-reverse',md:'row',lg:'row'}}}>
                    <Box sx={{padding:"0 1em"}}>
                        <Typography fontWeight={'bold'} variant='h4'>Buying House:</Typography>
                        <Typography variant='p'>As a buying house, Supreme Supplies BD serves as a strategic intermediary between international buyers and local manufacturers, facilitating seamless communication, negotiation, and procurement processes. With our extensive network of suppliers and manufacturers, we specialize in sourcing high-quality garments and textiles that meet the exact specifications and requirements of our clients.</Typography> <br />
                        <Typography variant='p'>Our experienced team of professionals possesses in-depth knowledge of the global fashion industry, allowing us to provide valuable insights and guidance to our clients throughout the sourcing and production process. From initial product development and sampling to quality assurance and logistics management, we ensure that every step of the supply chain is meticulously executed to meet our clients' expectations and deadlines.</Typography><br />
                        <Typography variant='p'>At Supreme Supplies BD, we prioritize transparency, integrity, and ethical business practices in all our dealings. We work closely with our clients to understand their unique needs and objectives, providing tailored solutions that optimize cost efficiency, product quality, and delivery timelines. Our commitment to excellence and customer satisfaction has earned us a reputation as a reliable and trusted partner in the buying house sector.</Typography>
                    </Box>
                    <Box> <img style={{width:'100%',height:'100%'}} src={buyingHouse} alt="elasticImage"/></Box>
        </Box>
        <Box  sx={{display:'inline-flex', justifyContent:"space-around",alignItems:'center',flexDirection:{xs:'column',md:'row',lg:'row'}}}>
                <Box> <img style={{width:'100%',height:'100%'}} src={machine} alt="fishFood"/></Box>
                    <Box sx={{padding:"0 1em"}}>
                        <Typography fontWeight={'bold'} variant='h4'>Clothing Production:</Typography>
                        <Typography variant='p'>In addition to our buying house operations, Supreme Supplies BD is also a leading manufacturer of high-quality garments for both domestic and international markets. With state-of-the-art production facilities and a skilled workforce, we have the capabilities to produce a wide range of clothing items, including but not limited to, shirts, trousers, dresses, outerwear, and activewear.</Typography><br />
                        <Typography variant='p'>Our clothing production process adheres to the highest standards of quality and craftsmanship, ensuring that every garment that bears the Supreme Supplies BD label is of impeccable quality and durability. From fabric selection and pattern making to cutting, sewing, and finishing, we employ rigorous quality control measures at every stage of production to maintain consistency and excellence in our products.</Typography><br />
                        <Typography variant='p'>At Supreme Supplies BD, we understand the importance of sustainability and social responsibility in the garment manufacturing industry. That's why we prioritize eco-friendly practices and ethical labor standards in our operations, striving to minimize our environmental footprint and uphold the dignity and rights of our workers.</Typography><br />
                    </Box>
        </Box>
        </section>
        <Typography variant='p'>
        In conclusion, the Garments Sector of Supreme Supplies BD is dedicated to providing comprehensive solutions that meet the diverse needs of our clients in the fashion and textile industry. Whether you're a fashion retailer looking to source quality garments or a brand seeking reliable manufacturing partners, we have the expertise and resources to help you achieve your goals. Contact us today to learn more about how we can elevate your fashion business with our exceptional services.
        </Typography>
       </Box>
    );
};

export default Garments;